import React from "react";
import styled from "styled-components";
import { spacers, breakpoints } from "../styles/variables";
import Imgix from "react-imgix";
import { imgix } from "../styles/variables";

interface TeamProps {
  img: string;
  name: string;
  bildung: string;
  job: string;
  email: string;
}

const TeamDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${spacers.s}px;

  > div {
    text-align: center;

    &:first-of-type {
      width: 160px;
      height: 160px;
      border-radius: 80px;
      overflow: hidden;
      margin: 0 auto;

      > img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    &:nth-of-type(2) {
      p.lead {
        text-transform: uppercase;
      }
      > * {
        margin: 0;
      }
    }
  }
  @media screen AND (min-width: ${breakpoints.l}px) {
    grid-template-columns: 160px 1fr;
    grid-gap: ${spacers.m}px;

    div:nth-of-type(2) {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

const Mitarbeiter: React.FC<TeamProps> = ({
  img,
  name,
  bildung,
  job,
  email,
}) => {
  return (
    <TeamDiv>
      <div>
        <Imgix
          src={img}
          imgixParams={imgix.teamImg}
          disableQualityByDPR
          disableSrcSet
        />
      </div>
      <div>
        <p className="lead">{name}</p>
        <p>
          {bildung}
          <br />
          {job}
          <br />
          <a href={"mailto:" + email}>{email}</a>
        </p>
      </div>
    </TeamDiv>
  );
};

export default Mitarbeiter;
