import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import DefaultLayout from "../../layouts/default";
import PartialPageImgHeader from "../../components/PartialPageImgHeader";
import TitleXL from "../../components/TitleXL";
import Wrapper from "../../components/Wrapper";
import HorizontalWrapper from "../../components/HorizontalWrapper";
import HalfGrid from "../../components/HalfGrid";
import Mitarbeiter from "../../components/Mitarbeiter";
import { Helmet } from "react-helmet";

interface TeamQueryProps {
  cms: {
    entry: {
      title: string;
      mainImage: [{ url: string }];

      children: [
        {
          title: string;
          mainImageTeam: [{ url: string }];
          mitarbeiterBildung: string;
          mitarbeiterBeruflicheStellung: string;
          mitarbeiterEmail: string;
        }
      ];
    };
  };
}

const Team = () => {
  const data: TeamQueryProps = useStaticQuery(graphql`
    query {
      cms {
        entry(type: "team") {
          title
          ... on CMS_inhalt_team_Entry {
            mainImage {
              url
            }
          }

          children {
            title

            ... on CMS_inhalt_mitarbeiter_Entry {
              mainImageTeam {
                url
              }
              mitarbeiterBildung
              mitarbeiterBeruflicheStellung
              mitarbeiterEmail
            }
          }
        }
      }
    }
  `);
  const entry = data.cms.entry;
  const team = data.cms.entry.children;
  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <PartialPageImgHeader image={entry.mainImage[0].url} />
      <HorizontalWrapper>
        <TitleXL spacingTop>{entry.title}</TitleXL>
      </HorizontalWrapper>
      <Wrapper>
        <HalfGrid>
          {team.map((mitarbeiter) => (
            <Mitarbeiter
              key={mitarbeiter.mitarbeiterEmail}
              img={mitarbeiter.mainImageTeam[0].url}
              name={mitarbeiter.title}
              bildung={mitarbeiter.mitarbeiterBildung}
              job={mitarbeiter.mitarbeiterBeruflicheStellung}
              email={mitarbeiter.mitarbeiterEmail}
            />
          ))}
        </HalfGrid>
      </Wrapper>
    </DefaultLayout>
  );
};

export default Team;
